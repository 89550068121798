.main-div{
  margin-top: 93px;
  margin: 93px 20px;
}
.table-div{
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  overflow-x: auto; /* Allow horizontal scrolling */

}

.table {
  min-width: 400px; /* Ensure table has a minimum width */
}

.table th,
.table td {
  font-size: 13px;
  text-align: center;
  padding: 10px;
}
  
.material-page {
  text-align: center;
}

.material-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Space between materials */
  margin-top: 30px;
}

.material-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  transition: transform 0.3s;
}

.material-item:hover {
  transform: translateY(-5px);
}

.material-name {
  flex-grow: 1;
  text-align: left;
}

.delete-button {
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
}

.delete-button:hover {
  color: #ff1a1a;
}
.search-container {
  display: flex;
  flex-direction: row; /* Default to row layout */
  justify-content: space-between; /* Distribute space between elements */
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .search-container {
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center elements vertically */
    align-items: center; /* Center elements horizontally */
  }
}

/* Search Input */
.search-container input[type="search"] {
  width: 100%; /* Full width by default */
  max-width: 300px; /* Limit maximum width for readability */
  padding: 7px 10px;
  border: 1px solid #d1d5db; /* Light gray border color */
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  color: #6b7280; /* Gray text color */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; /* Add spacing below input for small screens */
}

.search-container input[type="search"]::placeholder {
  color: #6b7280; /* Gray placeholder text color */
}

/* Add Button */
.search-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px; /* Add spacing between button and input for large screens */
}

@media (max-width: 576px) {
  .search-button {
    width: 100%; /* Full width for small screens */
    margin-left: 0; /* Reset margin on small screens */
  }
}


@media (max-width: 768px) {
  .main-div {
    margin: 20px;
  }

  .table th,
  .table td {
    font-size: 11px; /* Smaller font size for mobile */
    padding: 8px;
  }

  .search-container input {
    width: 100%; /* Full width for the search input */
    margin-bottom: 10px; /* Add spacing below the input */
  }

  .badge {
    font-size: 12px; /* Adjust button font size */
    padding: 10px 15px;
  }

  .upload-btn {
    font-size: 11px;
  }

  .delete-button {
    font-size: 16px; /* Slightly smaller delete icon */
  }

  .view-doc-btn {
    font-size: 11px;
  }
}