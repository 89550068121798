.items-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.order-name,
.order-id {
  font-size: 1.25rem;
  font-weight: 500;
}

.order-name span,
.order-id span {
  font-weight: 700;
  color: #007bff;
}

.table {
  margin-top: 20px;
}

.table-header {
  background-color: #007bff;
  color: white;
}

.table-hover tbody tr:hover {
  background-color: #f1f3f5;
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
  text-align: center;
}

.table tbody td {
  text-align: center;
  vertical-align: middle;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 123, 255, 0.05);
}

.table-hover tbody tr:hover td {
  background-color: #eaf4ff !important;
}

/* Items.css */

.order-info h5 {
  margin: 0; /* Remove default margin */
  font-size: 1rem; /* Default font size for labels */
}

.order-value {
  font-size: 1.2rem; /* Font size for values */
  font-weight: bold; /* Make the value bold */
}

@media (max-width: 480px) {
  .order-info h5 {
    font-size: 0.9rem; /* Smaller font size for labels on very small screens */
  }

  .order-value {
    font-size: 1rem; /* Smaller font size for values */
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  transition: background-color 0.3s, color 0.3s;
}

.step-label {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #949494;
}

.step.active .step-label {
  color: #007bff;
}

.step-line {
  height: 2px;
  background-color: #ccc;
  transition: background-color 0.3s;
  margin-top: 26px;
}

.step-line.active {
  background-color: #007bff;
}

/* Smooth transitions for button states */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-secondary {
  background-color: #6c757d;
  /* border-color: #6c757d; */
}

.btn:hover {
  transform: scale(1.1);
}

.svgIcn1 {
  height: 20px;
  width: 20px;
}

.svgIcn2 {
  height: 20px;
  width: 20px;
}

.svgIcn3 {
  height: 25px;
  width: 25px;
}

.svgIcn4 {
  height: 15px;
  width: 17px;
}
.decline-order {
  cursor: not-allowed;
}

.back-icon {
  height: 19px;
  width: 19px;
}

.data-tabs,
.data-tabs2 {
  border: 1px solid #556ee6;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0;
  list-style-type: none;
  margin-bottom: 5px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease; /* Add transitions */
}

.data-tabs1 {
  border: 1px solid #556ee6;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0;
  list-style-type: none;
  margin-bottom: 5px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease; /* Add transitions */
}

.data-tabs:hover,
.data-tabs1:hover,
.data-tabs2:hover {
  background-color: #f0f0f0; /* Example hover effect */
  color: #333;
}

.text-primary {
  color: #556ee6;
}

.bg-primary {
  background-color: #556ee6;
}

.text-white {
  color: white;
}

.bg-primary {
  background-color: #556ee6;
}

.font-size-13 {
  font-size: 13px;
}
.orderimg-div {
  height: 590px;
  position: relative; /* Add position relative to allow absolute positioning of text */
}

.orderImg {
  position: absolute;
  top: 25px;
  height: 90%;
  width: 100%;
  padding: 0px 110px;
}

@media (max-width: 1570px) {
  .orderImg {
    padding: 0px 70px;
  }
}

@media (max-width: 1270px) {
  .orderImg {
    padding: 0px 20px;
  }
}

@media (max-width: 1030px) {
  .orderImg {
    padding: 0px 10px;
  }
}

@media (max-width: 992px) {
  .orderImg {
    padding: 0px 150px;
  }
}

@media (max-width: 764px) {
  .orderImg {
    padding: 0px 70px;
  }
}

@media (max-width: 576px) {
  .orderImg {
    padding: 0px 20px;
  }
}

@media (max-width: 420px) {
  .orderImg {
    padding: 0px 0px;
    height: 70%;
    top: 60px;
  }
}

.form-tab1 {
  border-radius: 6px 0px 0px 6px;
}

.form-tab2 {
  border-radius: 0px 6px 6px 0px;
}

.note-text {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 0px; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above the image */
  background: rgb(
    200,
    203,
    220
  ); /* Optional: add a semi-transparent background */
  padding: 0px 20px;
  width: 99%;
  border-radius: 5px; /* Optional: make the background look nicer */
}

.note-title {
  font-size: 14px;
  font-weight: bolder;
  color: #0c54a1;
  /* display: block; */
}

.note-body {
  font-size: 13px;
  font-weight: bolder;
  color: #2a2c2f;
  /* display: block; */
}

.note-div {
  padding: 0px 1000px;
}

.form-div {
  width: 100%;
}

.tab-div {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid #e0e0e0; /* Light bottom border for the entire tab container */
}

/* Individual Tab Styling */
.tab-item {
  flex: 1;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
  color: #6c757da8;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

/* Active Tab Styling with Bold Bottom Border */
.tab-active {
  color: #000000b1;
  font-weight: 600;
  border-bottom: 3px solid #007bff;
}

/* Hover Effect */
.tab-item:hover {
  /* color: #000000; */
  color: #000000b1;
}

/* Underline Hover Effect for Non-active Tabs */
.tab-item:not(.tab-active)::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background-color: #007bff;
  transition: width 0.3s ease;
}

/* Expand the underline on hover */
.tab-item:hover:not(.tab-active)::after {
  width: 60%;
}

/* Content Area */
.tab-content {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}

/* For fade effect */
.fade-enter {
  opacity: 5;
  transform: translateY(10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1300ms, transform 300ms;
}

.add-item-btn {
  height: 20px;
  border: none;
  background-color: transparent;
}

.item-form {
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
   */
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.img-order-div {
  margin-top: 150px;
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  white-space: nowrap; /* Prevents text wrapping, enabling horizontal scroll */
}
.notes-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.note-title-note-order {
  font-size: 1.5rem;
  font-weight: bold;
  color: #495057;
  margin-bottom: 10px;
}
.note-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #6c757d;
}

.item-modal {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  /* height: 100vh;  */
}
/* .item-modal {
  padding: 20px;
  background-color: #f9f9f9;
} */

.form-container {
  width: 100%; /* Optional: Adjust width as needed */
  max-width: 1180px; /* Optional: Set a maximum width */
  margin: 10px auto; /* Center horizontally */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-bottom: 10px;
  font-size: 16px;
}

.form-inline {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between fields */
  align-items: center;
}

.form-group-inline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group-inline label {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}

.form-input-inline {
  width: 120px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.submit-button-inline {
  margin-left: 30px;
  font-size: 25px; /* Slightly increase font size */
  border: none;
  background-color: transparent;
  color: #007bff; /* Text color matches the outline */
  /* border-radius: 50%; Circle shape */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth hover effect */
  display: flex;
  align-items: center;
  justify-content: right;
}

.submit-button-inline:hover {
  /* background-color: #007bff; Fill with blue on hover */
  color: rgb(41, 38, 38); /* Change text color to white */
  transform: scale(1.1); /* Slightly enlarge the button */
  /* box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3); Add a shadow */
}

.submit-button-inline i {
  font-size: 18px; /* Ensure the icon is prominent */
}

.order-loader {
  height: 400px;
  width: 100%;
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.unit-main {
  display: flex;
  border: 1px solid gray;
  width: 120px;
  height: 38px;
}

.inch-unit,
.mm-unit {
  cursor: pointer;
}

.order-details h5 {
  font-size: 16px;
  margin-bottom: 6px;
}
.order-details span {
  font-size: 14px;
}
