.query-img-div {
  /* border: 2px solid black; */
  height: 350px;
  width: 300px;
}

/* .query-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
} */

.query-img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-button {
  padding: 6px 10px;

  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;
}

.custom-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 8px rgba(0, 123, 255, 0.5);
  transform: translateY(-2px);
}

.custom-button:focus {
  outline: none;
}

.view-button {
  background-color: #007bff;
  margin: 5px 5px 5px 0px;
}

.download-button {
  background-color: #28a745;
}

.download-button:hover {
  background-color: #218838;
  box-shadow: 0 6px 8px rgba(40, 167, 69, 0.5);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}
